import React from "react"
import { Link } from "gatsby"
import { DiscussionEmbed } from "disqus-react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: `/`, title: `Index page` },
  }

  // console.log(`short name is '${process.env.GATSBY_DISQUS_NAME}'`)
  return (
    <Layout>
      <SEO title="Home" />
      <h1>Hi people</h1>
      <p>Welcome to your new Gatsby site.</p>
      <p>Now go build something great.</p>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
      <Link to="/insta/">Go to my insta overview</Link>
      <DiscussionEmbed {...disqusConfig} />
    </Layout>
  )
}

export default IndexPage
